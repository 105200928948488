<!-- eslint-disable -->
<template>
  <div class="footer">
    <v-container class="footer-container">
      <v-row style="text-align:left;" class="shown-sm-and-down">
        <v-col  cols="12" md="3">
          <img class="footer-logo" :src="require('../../../../assets/logo_invert.png')"/>
          <div style="display:inline-block;margin-top:-10px;float:right">
          <a style="text-decoration:none" href="https://www.facebook.com/TLM-1751217361604753/" target="_blank"><v-icon class="vertical-center menu-icon-footer cursor-pointer">mdi-facebook</v-icon></a>
          <a style="text-decoration:none" href="https://www.instagram.com/tlm_algerie/" target="_blank"><v-icon class="vertical-center menu-icon-footer cursor-pointer" >mdi-instagram</v-icon></a>
          <v-icon class="vertical-center menu-icon-footer cursor-pointer" >mdi-twitter</v-icon>
          <v-icon class="vertical-center menu-icon-footer cursor-pointer" >mdi-linkedin</v-icon>
          </div>
          <p class="hidden-sm-and-down" style="margin-top:20px">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna</p>
          <p class="hidden-sm-and-down" style="margin-top:20px;padding:0px 100px 10px 0px">Coop El Khanssa BT/A1 Hey El Abtal, Annaba</p>
          <p style="line-height:1px;margin-top:20px"><span style="color:white">Email : </span>tlmalgerie@yahoo.com</p>
          <p style="line-height:1px"><span style="color:white">Tlm Logistique : </span>0655 51 67 03 | 0655 51 67 01</p>
          <p style="line-height:1px"><span style="color:white">SOS : </span>0777 20 52 05</p>
        </v-col>
        <v-spacer/>
        <v-col style="width:47%;display:inline-block" cols="12" md="2" >
          <h3 style="">Nos partenaires</h3>
          <v-icon  class="icon-class">mdi-circle-medium</v-icon>
          <a style="text-decoration:none;text-transform: capitalize;color:#adb6ca" href="#">EUROPASPORT</a><br>
          <v-icon  class="icon-class">mdi-circle-medium</v-icon>
          <a style="text-decoration:none;text-transform: capitalize;color:#adb6ca" href="#">CLEMENT DESIGN</a><br>
          <v-icon  class="icon-class">mdi-circle-medium</v-icon>
          <a style="text-decoration:none;text-transform: capitalize;color:#adb6ca" href="#">LABORATOIRES EVOLAB</a><br>
          <v-icon  class="icon-class">mdi-circle-medium</v-icon>
          <a style="text-decoration:none;text-transform: capitalize;color:#adb6ca" href="#">PHARMACIE CENTRALE</a><br>
          <v-icon  class="icon-class">mdi-circle-medium</v-icon>
          <a style="text-decoration:none;text-transform: capitalize;color:#adb6ca" href="#">SARL DIAGNOMED</a><br>
        </v-col>
        <v-col  style="width:47%;display:inline-block" cols="12" md="2" >
          <h3 style="">Services</h3>
          <v-icon  class="icon-class">mdi-circle-medium</v-icon>
          <router-link style="text-decoration:none;text-transform: capitalize;color:#adb6ca" to="/about-us">Qui somme nous?</router-link><br>
          <v-icon  class="icon-class">mdi-circle-medium</v-icon>
          <a style="text-decoration:none;text-transform: capitalize;color:#adb6ca" href="#">TLM LOGISTIQUE</a><br>
          <v-icon  class="icon-class">mdi-circle-medium</v-icon>
          <a style="text-decoration:none;text-transform: capitalize;color:#adb6ca" href="#">TLM EXPRESS</a><br>
          <v-icon  class="icon-class">mdi-circle-medium</v-icon>
          <a style="text-decoration:none;text-transform: capitalize;color:#adb6ca" href="#">S.O.S</a><br>
        </v-col>
        <v-spacer/>
      </v-row>
      <v-row style="text-align:left;" class="hidden-sm-and-down">
        <v-col  cols="12" md="3">
          <img class="footer-logo" :src="require('../../../../assets/logo_invert.png')"/>
          <p style="margin-top:20px">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna</p>
          <p style="margin-top:20px;padding:0px 100px 10px 0px">Coop El Khanssa BT/A1 Hey El Abtal, Annaba</p>
          <p style="line-height:1px"><span style="color:white">Email : </span>tlmalgerie@yahoo.com</p>
          <p style="line-height:1px"><span style="color:white">Tlm Logistique : </span>0655 51 67 03 | 0655 51 67 01</p>
          <p style="line-height:1px"><span style="color:white">SOS : </span>0777 20 52 05</p>
        </v-col>
        <v-spacer/>
        <v-col  cols="12" md="2" >
          <h3 style="">Nos partenaires</h3>
          <div class="footer-item">
          <v-icon style="width:30px;height:40px;" class="icon-class">mdi-circle-medium</v-icon>
          <a style="text-decoration:none;text-transform: capitalize;color:#adb6ca" href="#">EUROPASPORT</a><br>
          </div>
          <div class="footer-item">
          <v-icon style="width:30px;height:40px;" class="icon-class">mdi-circle-medium</v-icon>
          <a style="text-decoration:none;text-transform: capitalize;color:#adb6ca" href="#">CLEMENT</a><br>
          </div>
          <div class="footer-item">
          <v-icon style="width:30px;height:40px;" class="icon-class">mdi-circle-medium</v-icon>
          <a style="text-decoration:none;text-transform: capitalize;color:#adb6ca" href="#">LABORATOIRES EVOLAB</a><br>
          </div>
          <div class="footer-item">
          <v-icon style="width:30px;height:40px;" class="icon-class">mdi-circle-medium</v-icon>
          <a style="text-decoration:none;text-transform: capitalize;color:#adb6ca" href="#">PHARMACIE CENTRALE</a><br>
          </div>
          <div class="footer-item">
          <v-icon style="width:30px;height:40px;" class="icon-class">mdi-circle-medium</v-icon>
          <a style="text-decoration:none;text-transform: capitalize;color:#adb6ca" href="#">SARL DIAGNOMED</a><br>
          </div>
        </v-col>
        <v-col  cols="12" md="2" >
          <h3 style="">Services</h3>
          <div class="footer-item">
            <v-icon style="width:30px;height:40px;" class="icon-class">mdi-circle-medium</v-icon>
            <router-link style="text-decoration:none;text-transform: capitalize;color:#adb6ca" to="/about-us">Qui somme nous?</router-link>
          </div>
          <div class="footer-item">
          <v-icon style="width:30px;height:40px;" class="icon-class">mdi-circle-medium</v-icon>
          <a style="text-decoration:none;text-transform: capitalize;color:#adb6ca" href="#">TLM LOGISTIQUE</a><br>
          </div>
          <div class="footer-item">
          <v-icon style="width:30px;height:40px;" class="icon-class">mdi-circle-medium</v-icon>
          <a style="text-decoration:none;text-transform: capitalize;color:#adb6ca" href="#">TLM EXPRESS</a><br>
          </div>
          <div class="footer-item">
          <v-icon style="width:30px;height:40px;" class="icon-class">mdi-circle-medium</v-icon>
          <a style="text-decoration:none;text-transform: capitalize;color:#adb6ca" href="#">S.O.S</a><br>
          </div>
        </v-col>
        <v-spacer/>
        <v-col  cols="12" md="3" >
          <h3 style="margin-bottom:20px">Télécharger l'application</h3>
          <img style="margin-right:20px;" :src="require('../../../../assets/gplay2.svg')" height="40"/>
          <img :src="require('../../../../assets/appstore2.svg')" height="40"/>
          <h3 style="margin:30px 0px 20px 0px">Contacter Nous</h3>
          <v-btn class="contact-us-btn"><span>Contact Us</span></v-btn>
          <div style="display:block;margin-top:5px">
          <a style="text-decoration:none" href="https://www.facebook.com/TLM-1751217361604753/" target="_blank"><v-icon class="vertical-center menu-icon-footer cursor-pointer" >mdi-facebook</v-icon></a>
          <a style="text-decoration:none" href="https://www.instagram.com/tlm_algerie/" target="_blank"><v-icon class="vertical-center menu-icon-footer cursor-pointer" >mdi-instagram</v-icon></a>
          <v-icon class="vertical-center menu-icon-footer cursor-pointer" >mdi-twitter</v-icon>
          <v-icon class="vertical-center menu-icon-footer cursor-pointer" >mdi-linkedin</v-icon>
          </div>
        </v-col>
      </v-row>
      <div style="text-align:center;justify-content:center">
        <hr style="background-color:white">
      </div>
    </v-container>
    <div style="text-align:center;justify-content:center;padding:0px 0px 10px 0px">
      <h2>Copyright © 2022 tlmexp</h2>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
      links: [
        {
          href: '#',
          text: 'A propos',
        },
        {
          href: '#',
          text: 'Contacter Nous',
        },
        {
          href: '#',
          text: 'Droits et licences',
        },
      ],
    }),
  }
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat');
h3 {
  color: #ffe209;
  font-size: 18px;
}
#dashboard-core-footer {
  position: absolute;
  bottom: 50px;
  width: 100%;
  height: 2.5rem;
}
a {
  font-size: 13px;
  text-decoration: none;
  text-transform: uppercase;
}
.footer {
   /* position: absolute; */
   height: 0px;
   left: 0;
   bottom: 0;
   width: 100%;
   background-color: transparent;
   color: #adb6ca;
   font-size:12px;
   /* text-align: center; */
}
.icon-class {
  color: white !important;
}
.contact-us-btn {
  background-color:#ffe209 !important;
  border-radius:10px !important;
}

.contact-us-btn:hover span {
  color:#0b2963;
  transform: scale(1.1);
  font-weight: 700;
}
.cursor-pointer {
    cursor : pointer
}
.footer-container {
  max-width: 1300px;
  margin:10px 120px 0px 150px;
}

.menu-icon-footer {
  color:#ffe209 !important;
  padding:3px 7px !important;
}

.menu-icon-footer:hover {
  color:white !important;
}

.footer-item:hover {
  transform: scale(1.1);
}

@media (min-width:1600px) {
  .footer h3 {
    font-size:22px;
  }
  .footer a {
    font-size:14px;
  }
  .footer{
    font-size:14px;
  }
  .footer-container {
    max-width: 1600px;
  }
}
@media (max-width:800px) {
  .footer-container {
    margin:0px 10px 0px 10px;
  }
  .footer-logo {
    width:20%;
  }
  .footer h3 {
    font-size:16px;
  }
  .footer a {
    font-size:12px;
  }
  .footer{
    font-size:11px;
  }
}
</style>
